/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @next/next/no-css-tags */
/* eslint-disable @next/next/no-sync-scripts */
import Head from 'next/head';
import type { AppProps } from 'next/app';

import { AppProvider } from '~/hooks';

import { GlobalStyle } from '~/styles/global';

export default function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="Criada pela RS Solutions com o propósito de..."
        />

        <meta property="og:image" content="./images/logo.png" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />

        <meta
          name="description"
          content="A solução digital mais completa para o seu negócio! Venda em vários canais. Administre em apenas um lugar."
        />
        <meta
          property="og:description"
          content="A solução digital mais completa para o seu negócio! Venda em vários canais. Administre em apenas um lugar."
        />

        <meta property="og:title" content="Plataforma Facilita Varejo" />
        <meta property="og:site_name" content="Plataforma Facilita Varejo" />

        <meta property="og:image" content="./images/favicon.png" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />

        <link rel="icon" href="./images/favicon.png" type="image/x-icon" />
        <link
          rel="shortcut icon"
          href="./images/favicon.png"
          type="image/x-icon"
        />

        <title>Plataforma Facilita Varejo</title>
      </Head>

      <AppProvider>
        <GlobalStyle />

        <Component {...pageProps} />
      </AppProvider>
    </>
  );
}
