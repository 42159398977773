import { ThemeProvider } from 'styled-components';
import { ChakraProvider } from '@chakra-ui/react';

import { FCWithChildren } from '~/types/FCWithChildren';

import { theme } from '~/styles/theme';

import { ModalProvider } from './modal';
import { FacebookPixelProvider } from './facebookPixel';

export const AppProvider: FCWithChildren = ({ children }) => {
  return (
    <ChakraProvider resetCSS={false}>
      <ThemeProvider theme={theme}>
        <FacebookPixelProvider>
          <ModalProvider>{children}</ModalProvider>
        </FacebookPixelProvider>
      </ThemeProvider>
    </ChakraProvider>
  );
};
