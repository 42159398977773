import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import { MdClose } from 'react-icons/md';

import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react';

import { StyledModalBody } from './styles';

interface IProps {
  title: string;
  children: ReactNode;
}

export interface IModalHandles {
  open(): void;
  close(): void;
}

const BaseModal: ForwardRefRenderFunction<IModalHandles, IProps> = (
  { title, children },
  ref
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      open: onOpen,
      close: onClose,
    }),
    [onOpen, onClose]
  );

  return (
    <>
      <ChakraModal
        isOpen={isOpen}
        onClose={onClose}
        id="chakra-modal-global-style"
        isCentered
      >
        <ModalOverlay />

        <ModalContent>
          <StyledModalBody>
            <header>
              <h3>{title}</h3>

              <button type="button" onClick={onClose}>
                <MdClose />
              </button>
            </header>
            {children}
          </StyledModalBody>
        </ModalContent>
      </ChakraModal>
    </>
  );
};

export const Modal = forwardRef(BaseModal);
