export const theme = {
  colors: {
    // BASICS
    primary: '#ED1C24',
    onPrimary: '#FFF',
    secondary: '#ED1C24',
    tertiary: '#CFCFCF',
    overshadowed: '#242424',

    // BACKGROUNDS
    lightBackground: '#f8f7fc',
    onLightBackground: '#010101',
    darkBackground: '#A7ABAA',
    onDarkBackground: '#53565A',
  },

  // BORDER RADIUS
  borderRadius: {
    sm: '8px',
    md: '16px',
    xl: '32px',
    xxl: '48px',
    rounded: '50%',
  },

  // MEASURES
  maxWidth: '1300px',
  sectionSpacing: '5rem',
  mobileSectionSpacing: '3rem',
};
