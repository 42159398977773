import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Frutiger' ;
    src: url('https://fonts.cdnfonts.com/s/13615/Frutiger.woff');
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 1px;

    @media (max-width: 900px) {
      text-align: center;
    }
  }

  html, body, #__next {
    width: 100%;
    height: 100%;
    letter-spacing: 0.5px;
  }


  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%;
    }
  }

  body, input textarea, select, button {
    font-weight: 400;
    font-size: 1rem;
    font-family: 'Frutiger', sans-serif !important;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;

    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  input {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  #chakra-modal-chakra-modal-global-style {
    max-width: 800px;
    margin-right: 16px;
    margin-left: 16px;
  }

  .chakra-alert {
   z-index: 99999999;
  }
`;
