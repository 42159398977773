import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const {
  API_URI,
  ADMIN_API_URI,
  FACILITA_VAREJO_PORTAL_URL,
  PDV_FACIL_SITE_URL,
  MENU_FACIL_SITE_URL,
  TOTEM_SITE_URL,
  PRA_VENDAS_SITE_URL,
  FACEBOOK_PIXEL_CODE,
  POS_FACIL_RESALE_SITE_URL,
} = publicRuntimeConfig;
