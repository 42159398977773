import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
  useCallback,
} from 'react';

import { IModalHandles, Modal } from '~/components/Modal';

import { FCWithChildren } from '~/types/FCWithChildren';

interface IModalContextData {
  openModal(modalTitle: string, modalContent: ReactNode): void;
  closeModal(): void;
}

const ModalContext = createContext({} as IModalContextData);

const ModalProvider: FCWithChildren = ({ children }) => {
  const modalRef = useRef<IModalHandles>(null);

  const [content, setContent] = useState<ReactNode>(null);
  const [title, setTitle] = useState<string>('');

  const openModal = useCallback((modalTitle, modalContent) => {
    modalRef.current?.open();
    setTitle(modalTitle);
    setContent(modalContent);
  }, []);

  const closeModal = useCallback(() => {
    modalRef.current?.close();
    setTitle('');
    setContent(null);
  }, []);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <Modal ref={modalRef} title={title}>
        {content}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

const useModal = (): IModalContextData => {
  return useContext(ModalContext);
};

export { ModalProvider, useModal };
