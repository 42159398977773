/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createContext, useCallback, useContext, useEffect } from 'react';

import { useRouter } from 'next/router';

import { FACEBOOK_PIXEL_CODE } from '~/constants';

import { FCWithChildren } from '~/types/FCWithChildren';

type TTrackEvent = 'AddToCart' | 'ViewContent';
interface IFacebookPixelContextData {
  track(event: TTrackEvent, params?: Record<string, any>): void;
}

const FacebookPixelContext = createContext<IFacebookPixelContextData>(
  {} as IFacebookPixelContextData
);

const FacebookPixelProvider: FCWithChildren = ({ children }) => {
  const router = useRouter();

  const init = useCallback(() => {
    if (!FACEBOOK_PIXEL_CODE) return;

    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(FACEBOOK_PIXEL_CODE, undefined, {
          autoConfig: true,
          debug: false,
        });

        ReactPixel.pageView();
      });
  }, []);

  useEffect(() => {
    if (!FACEBOOK_PIXEL_CODE) {
      return () => null;
    }

    router.events.on('routeChangeComplete', init);

    return () => {
      router.events.off('routeChangeComplete', init);
    };
  }, [router, init]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const track = useCallback(
    (event: TTrackEvent, params?: Record<string, any>) => {
      if (!FACEBOOK_PIXEL_CODE) return;

      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(FACEBOOK_PIXEL_CODE, undefined, {
            autoConfig: true,
            debug: false,
          });

          ReactPixel.track(event, params);
        });
    },
    []
  );

  return (
    <FacebookPixelContext.Provider
      value={{
        track,
      }}
    >
      {children}
    </FacebookPixelContext.Provider>
  );
};

function useFacebookPixel(): IFacebookPixelContextData {
  return useContext(FacebookPixelContext);
}

export { FacebookPixelProvider, useFacebookPixel };
